import { createSlice } from '@reduxjs/toolkit';
import {
  EmptyObject,
  PaymentIntentReducerSchema,
} from 'views/types';

interface DepositInitialState {
  paymentIntent: PaymentIntentReducerSchema | EmptyObject;
}

const initialState: DepositInitialState = {
  paymentIntent: {},
};

export const depositSlice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    setPaymentIntentData(state, action) {
      return {
        ...state,
        paymentIntent: {
          ...action.payload,
        },
      };
    },
    setPaymentGatewayAccess(state, action) {
      const paymentIntent = action.payload;
      return {
        ...state,
        paymentIntent,
      };
    },
  },
});

export const { setPaymentIntentData, setPaymentGatewayAccess } = depositSlice.actions;
export default depositSlice.reducer;
